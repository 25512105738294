import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Data from "../../Assets/Data/Car";
import axios from "axios";

const postData = createAsyncThunk("postData", async (data, thunkAPI) => {
  const config = {
    method: "post",
    url: "",
    headers: {
      Authorization: "",
      "Content-Type": "",
    },
    data: data,
  };

  const response = await axios(config)
    .then(function (response) {
      // console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
  return response.data;
});

export const carSlice = createSlice({
  name: "car",
  initialState: {
    data: Data,
    validation: [],
    count: 1,
    retake: false,
    checkCount: 14,
  },
  reducers: {
    setCars(state, action) {
      return { ...state, data: action.payload };
    },
    carCount: (state, action) => {
      return {
        ...state,
        count: action.payload
          ? action.payload
          : state.count <= state.checkCount
          ? state.count + 1
          : state.count,

        // count: action.payload ? action.payload: isClaim ? state.count <= 12 : state.count <=14 ? state.count + 1 : state.count,

        // count: action.payload ? action.payload:state.count <= 14 ? action.payload + 1 : state.count,
      };
    },
    setRetake(state) {
      return { ...state, retake: true };
    },

    setUploaded(state) {
      return { ...state, uploaded: true };
    },
    setSendSMS(state) {
      return { ...state, sms: true };
    },

    carCountLimit(state, action) {
      return {
        ...state,
        count: action.payload,
      };
    },
    setCheckCount(state, action) {
      return {
        ...state,
        checkCount: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    // Do something while pending if you want.
    builder.addCase(postData.pending, (state, action) => {});
    // Do something when passes.
    builder.addCase(postData.fulfilled, (state, action) => {});
    // Do something if fails.
    builder.addCase(postData.rejected, (state, action) => {});
  },
});

export const {
  setCars,
  carCount,
  setRetake,
  setCheckCount,
  setUploaded,
  setSendSMS,
} = carSlice.actions;

export default carSlice.reducer;
