
import { createSlice } from "@reduxjs/toolkit";

export const isPAYDSlice = createSlice({
    name: "isPAYD",  
    initialState: false,  
    reducers: {
        setIsPAYD: (state, action) => action.payload,
    },
});


export const { setIsPAYD } = isPAYDSlice.actions;

export default isPAYDSlice.reducer;
