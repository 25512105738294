import Front from '../Images/front.svg'
import GreenFront from '../Images/greenFrontSedan.svg'
import RedFront from '../Images/redFrontSedan.svg'
import FrontRight from '../Images/front-right.svg'
import GreenFrontRight from '../Images/greenFrontRightSedan.svg'
import RedFrontRight from '../Images/redFrontRightSedan.svg'
import Right from '../Images/right.svg'
import GreenRight from '../Images/greenRightSedan.svg'
import RedRight from '../Images/redRightSedan.svg'
import RearRight from '../Images/rear-right.svg'
import GreenRearRight from '../Images/greenRearRightSedan.svg'
import RedRearRight from '../Images/redRearRightSedan.svg'
import Rear from '../Images/rear.svg'
import GreenRear from '../Images/greenRearSedan.svg'
import RedRear from '../Images/redRearSedan.svg'
import RearLeft from '../Images/rear-left.svg'
import GreenRearLeft from '../Images/greenRearLeftSedan.svg'
import RedRearLeft from '../Images/redRearLeftSedan.svg'
import Left from '../Images/left.svg'
import GreenLeft from '../Images/greenLeftSedan.svg'
import RedLeft from '../Images/redLeftSedan.svg'
import FrontLeft from '../Images/front-left.svg'
import GreenFrontLeft from '../Images/greenFrontLeftSedan.svg'
import RedFrontLeft from '../Images/redFrontLeftSedan.svg'
import WindshieldInside from '../Images/windshield-inside.svg'
import WindshieldOutside from '../Images/windshield-out.svg'
import EngineNumber from '../Images/engine-number.svg'
import ChassisNumber from '../Images/chassis-number.svg'
import OdoMeter from '../Images/odo-meter.svg'
import RcFront from '../Images/rc-front.svg'
import RcBack from '../Images/rc-back.svg'
import SedanFront from '../Images/sedan-front.svg'
import SedanFrontLeft from '../Images/sedan-front-left.svg'
import SedanFrontRight from '../Images/sedan-front-right.svg'
import SedanLeft from '../Images/sedan-left.svg'
import SedanRight from '../Images/sedan-right.svg'
import SedanRearRight from '../Images/sedan-rear-right.svg'
import SedanRear from '../Images/sedan-rear.svg'
import SedanRearLeft from '../Images/sedan-rear-left.svg'
import SedanWindshieldInside from '../Images/sedan-windshield-inside.svg'
import SedanWindshieldOutside from '../Images/sedan-windshield-outside.svg'
import SedanVehicleEngine from '../Images/vehicle-engine-no.svg'
import SedanVehicleChassis from '../Images/vehicle-chassis-no.svg'
import SedanSpeedometer from '../Images/sedan-speedometer.svg'
import SedanRCBookFront from '../Images/vehicle-rc-book-front.svg'
import SedanRCBookBack from '../Images/vehicle-rc-book-back.svg'


const lat = "19.1624"
const lon = "72.9376"

const leftSide = [
  {
    // id:"front-open",
    key: 1,
    isCar: false,
    id: "front",
    stencil: SedanFront,
    greenStencil: GreenFront,
    redStencil: RedFront,
    isUploadImg: null,
    isActive: true,
    isUpload: false,
    imgId: "Front",
    ImageSrc: Front,
    name: "Front",
    toValidate: true,
    UploadFile: null,
    message: "",
    latitude:lat,
    longitude:lon
  },

  {
    id: "front-right",
    isCar: false,
    isUploadImg: null,
    key: 2,
    stencil: SedanFrontRight,
    greenStencil: GreenFrontRight,
    redStencil: RedFrontRight,
    isActive: false,
    isUpload: false,
    imgId: "Front_Right",
    ImageSrc: FrontRight,
    name: "Front-right",
    toValidate: true,
    UploadFile: null,
    message: "",
    latitude:lat,
    longitude:lon
  },

  {
    id: "right",
    isCar: false,
    key: 3,
    stencil: SedanRight,
    greenStencil: GreenRight,
    redStencil: RedRight,
    isUploadImg: null,
    isActive: false,
    isUpload: false,
    imgId: "Right",
    ImageSrc: Right,
    name: "Right",
    toValidate: true,
    UploadFile: null,
    message: "",
    latitude:lat,
    longitude:lon
  },

  {
    id: "rear-right",
    key: 4,
    isCar: false,
    stencil: SedanRearRight,
    greenStencil: GreenRearRight,
    redStencil: RedRearRight,
    isUploadImg: null,
    isActive: false,
    isUpload: false,
    imgId: "Rear_Right",
    ImageSrc: RearRight,
    name: "Rear-right",
    toValidate: true,
    UploadFile: null,
    message: "",
    latitude:lat,
    longitude:lon
  },

  {
    id: "rear",
    key: 5,
    isCar: false,
    stencil: SedanRear,
    greenStencil: GreenRear,
    redStencil: RedRear,
    isUploadImg: null,
    isActive: false,
    isUpload: false,
    imgId: "Rear",
    ImageSrc: Rear,
    name: "Rear",
    toValidate: true,
    UploadFile: null,
    message: "",
    latitude:lat,
    longitude:lon
  },

  {
    id: "rear-left",
    key: 6,
    isCar: false,
    stencil: SedanRearLeft,
    greenStencil: GreenRearLeft,
    redStencil: RedRearLeft,
    isUploadImg: null,
    isActive: false,
    isUpload: false,
    imgId: "Rear_Left",
    ImageSrc: RearLeft,
    name: "Rear-left",
    toValidate: true,
    UploadFile: null,
    message: "",
    latitude:lat,
    longitude:lon
  },

  {
    id: "left",
    stencil: SedanLeft,
    greenStencil: GreenLeft,
    redStencil: RedLeft,
    key: 7,
    isCar: false,
    isUploadImg: null,
    isActive: false,
    isUpload: false,
    imgId: "Left",
    ImageSrc: Left,
    name: "Left",
    toValidate: true,
    UploadFile: null,
    message: "",
    latitude:lat,
    longitude:lon
  },

  {
    id: "front-left",
    key: 8,
    isCar: false,
    stencil: SedanFrontLeft,
    greenStencil: GreenFrontLeft,
    redStencil: RedFrontLeft,
    isUploadImg: null,
    isActive: false,
    isUpload: false,
    imgId: "Front_Left",
    ImageSrc: FrontLeft,
    name: "Front-left",
    toValidate: true,
    UploadFile: null,
    message: "",
    latitude:lat,
    longitude:lon
  },

  {
    id: "windshield-inside",
    key: 9,
    isCar: true,
    stencil: SedanWindshieldInside,
    isUploadImg: null,
    isActive: false,
    isUpload: false,
    imgId: "Windshield_Inside_Side",
    ImageSrc: WindshieldInside,
    name: "Windshield from-inside",
    toValidate: false,
    UploadFile: null,
    latitude:lat,
    longitude:lon
  },

  {
    id: "windshield-outside",
    key: 10,
    isCar: true,
    isUploadImg: null,
    stencil: SedanWindshieldOutside,
    isActive: false,
    isUpload: false,
    imgId: "Windshield_Outside_Side",
    ImageSrc: WindshieldOutside,
    name: "Windshield from-outside",
    toValidate: false,
    UploadFile: null,
    latitude:lat,
    longitude:lon
  },

  {
    id: "engine-no",
    key: 11,
    isCar: true,
    isUploadImg: null,
    stencil: SedanVehicleEngine,
    isActive: false,
    isUpload: false,
    imgId: "Engine_No",
    ImageSrc: EngineNumber,
    name: "Engine No.",
    toValidate: false,
    UploadFile: null,
    latitude:lat,
    longitude:lon
  },

  {
    id: "chassis-no",
    key: 12,
    isCar: true,
    isUploadImg: null,
    stencil: SedanVehicleChassis,
    redStencil: SedanVehicleChassis,
    greenStencil:SedanVehicleChassis,
    isActive: false,
    isUpload: false,
    imgId: "Chassis_No",
    ImageSrc: ChassisNumber,
    name: "Chassis No.",
    toValidate: false,
    UploadFile: null,
    latitude:lat,
    longitude:lon
  },

  {
    id: "odo-meter",
    key: 13,
    isCar: true,
    isUploadImg: null,
    stencil: SedanSpeedometer,
    redStencil: SedanSpeedometer,
    greenStencil: SedanSpeedometer,
    isActive: false,
    isUpload: false,
    imgId: "ODO_Meter",
    ImageSrc: OdoMeter,
    name: "ODO Meter",
    toValidate: false,
    UploadFile: null,
    latitude:lat,
    longitude:lon
  },

  {
    id: "rc-book-front",
    key: 14,
    isCar: true,
    stencil: SedanRCBookFront,
    isUploadImg: null,
    isActive: false,
    isUpload: false,
    imgId: "RC_Book_Front",
    ImageSrc: RcFront,
    name: "RC Book Front",
    toValidate: false,
    UploadFile: null,
    latitude:lat,
    longitude:lon
  },

  {
    id: "rc-book-back",
    key: 15,
    isCar: true,
    stencil: SedanRCBookBack,
    isUploadImg: null,
    isActive: false,
    isUpload: false,
    imgId: "RC_Book_Back",
    ImageSrc: RcBack,
    name: "RC Book Back",
    toValidate: false,
    UploadFile: null,
    latitude:lat,
    longitude:lon
  },

]

export default leftSide