import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setBreakin } from "../Redux/Features/breakinSlice";
import { setIsClaim } from "../Redux/Features/isClaimSlice";
import { useNavigate, useLocation } from "react-router-dom";
import Logo from "../Assets/Images/logo_hdfc.png";
import Loader from "../Components/Loader";
import Modal from "../Components/Modal";
import { setCars, carCount, setCheckCount } from "../Redux/Features/carSlice";
import { setIsPAYD } from "../Redux/Features/isPAYDSlice";

// import { setLatitude, setLongitude } from "../Redux/Features/locationSlice";

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const breakin = useSelector((state) => state.breakin);
  const querySearch = useLocation().search;

  const breakinID = new URLSearchParams(querySearch).get("breakinID");
  const claimNumber = new URLSearchParams(querySearch).get("claimNumber");
  let source = new URLSearchParams(querySearch).get("Source");
  let claimExists = false;

  if (claimNumber) {
    //console.log(claimExists);
    document.title = "Claim Inspection";
    const metaDescriptionTag = document.querySelector(
      'meta[name="description"]'
    );
    if (metaDescriptionTag) {
      metaDescriptionTag.setAttribute(
        "content",
        "Web based Vehicle Inspection for Claims"
      );
    }
  } else {
    document.title = "Breakin Inspection";
    const metaDescriptionTag = document.querySelector(
      'meta[name="description"]'
    );
    if (metaDescriptionTag) {
      metaDescriptionTag.setAttribute(
        "content",
        "Web based Vehicle Inspection for Breakin"
      );
    }
  }

  const [locationBlocked, setLocationBlocked] = useState(true);

  let requestBody = {};
  if (breakinID) {
    requestBody = { breakin_id: breakinID };
  } else if (claimNumber) {
    source = new URLSearchParams(querySearch).get("Source");
    if (!source) source = "WAPC";

    requestBody = { claim_num: claimNumber, source_app: source };
    claimExists = true;
  }

  const [tnc, setTnC] = useState(useSelector((state) => state.tnc) || false);
  const [loading, setLoading] = useState(true);
  var car = useSelector((state) => state.car);
  const isClaim = useSelector((state) => state.isClaim);
  const isPAYD = useSelector((state) => state.isPAYD);

  const no_cdf_sources = ['WAPC_MOBAPPS'];

  useEffect(() => {
    if (
      !breakin ||
      (breakin &&
        ((breakinID && breakin?.BRKIN_ID !== breakinID) ||
          (claimNumber && breakin?.claim_number !== claimNumber)))
    ) {
      if (claimExists) {
        // console.log(claimExists);
        dispatch(setIsClaim(claimExists));
      }
      // console.log(process.env.REACT_APP_STATIC_API_TOKEN)
      const requestOptions = {
        method: "POST",
        // mode:'no-cors',
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STATIC_API_TOKEN}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      };
      fetch(`${process.env.REACT_APP_API_URL}/breakin`, requestOptions)
        .then((results) => results.json())
        .then((data) => {
          data.no_cdf_sources = no_cdf_sources;
          dispatch(setBreakin(data));
          if(!claimExists){
            dispatch(setIsPAYD(data.PAYD === "POLICY_RN"));
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const handlePermissionChange = (event) => {
    const permissionStatus = event.target;

    if (permissionStatus.state === "granted") {
      setLocationBlocked(false);
    } else {
      setLocationBlocked(true);

      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocationBlocked(false);
        },
        (error) => {
          if (permissionStatus.state === "granted") {
            setLocationBlocked(false);
          } else setLocationBlocked(true);
        }
      );
    }
  };

  useEffect(() => {
    navigator.permissions.query({ name: "geolocation" }).then((result) => {
      result.onchange = handlePermissionChange;
      handlePermissionChange({ target: result }); // Initial check
    });
  }, []);

  // useEffect(() => {
  //   if (isClaim) {
  //     // car = Object.keys(fetchCar.leftSide.splice(15, 2));
  //     //console.log(car); 
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //     car = car.data.filter(data => data.imgId !== "RC_Book_Back" && data.imgId !== "RC_Book_Front");
  //     // console.log("modify car arr:", car);
  //     // dispatch(setCars(car));
  //     // setHasRunEffect(true);
  //     dispatch(setCars(car));
  //     dispatch(setCheckCount(isClaim))
  //   }
  // }, [isClaim]);
  // console.log(breakin.processFlag);

  useEffect(() => {
    if (isClaim) {

      const filteredCars = car.data.filter(
        data => data.imgId !== "RC_Book_Back" && data.imgId !== "RC_Book_Front"
      );
      
      dispatch(setCars(filteredCars));
      dispatch(setCheckCount(12)); 
    }
  
    if (isPAYD) {
      const filteredPAYDCars = car.data
        .filter(data => data.imgId === "Front" || data.imgId === "Chassis_No" || data.imgId === "ODO_Meter")
        .map(data => {
          
          if (data.imgId === "Chassis_No") {
            return { ...data, key: 2 };
          }
          if (data.imgId === "ODO_Meter") {
            return { ...data, key: 3 };
          }
          return data;
        });
  
      // Dispatch the modified list of cars for PAYD
      dispatch(setCars(filteredPAYDCars));
      dispatch(setCheckCount(2)); // Update check count when isPAYD is true
    }
  }, [isClaim, isPAYD]);
  // console.log(breakin.processFlag);
  if (breakin?.processFlag == 1 || (no_cdf_sources.includes(breakin.source_app) && breakin.is_ai_photos_uploaded)) {
    if ((claimExists && breakin.is_ai_photos_uploaded)) {
      // console.log("Reached here:", breakin.is_ai_photos_uploaded);
      return (
        <section className="success_container">
          <img src={Logo} alt="hdfc-ergo" />
          {/* <p id="thankyou_txt">Claim already in process.</p> */}
          <p id="success_para">
            The claim number you have submitted is already in process.
          </p>
        </section>
      );
    } else {
      return (
        <section className="success_container">
          <img src={Logo} alt="hdfc-ergo" />
          {/* <p id="thankyou_txt">Claim already in process.</p> */}
          <p id="success_para">
            The breakin ID you have submitted is already in process.
          </p>
        </section>
      );
    }
  } else if (breakin?.msg && breakin?.status == "INTERNAL_SERVER_ERROR") {
    return (
      <section className="success_container">
        <img src={Logo} alt="hdfc-ergo" />
        {/* <p id="thankyou_txt">Claim already in process.</p> */}
        <p id="success_para">{breakin.msg}</p>
      </section>
    );
  } else {
    if (claimExists) {
      return (
        <>
          {locationBlocked && (
            <Modal>
              <div className="modalContainer">
                <div className="retake_modal">
                  <span>
                    Please allow location access from your browser and refresh.
                  </span>
                  <div className="yes_no_btn">
                    <button
                      style={{ backgroundColor: "#e42125" }}
                      onClick={() => window.location.reload()}
                    >
                      Refresh
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
          )}
          {Object.keys(breakin).length !== 0 && breakin.claim_number ? (
            <>
              <div className="container">
                <div className="header_container">
                  <span>Claim Details:</span>
                  <p>
                    Please complete the inspection to process your claim
                    request.
                  </p>
                </div>
                <div className="box_container">
                  <div className="car_details">
                    <div className="inspection_id">
                      <p className="grey_shade">Claim Number</p>
                      <p className="dark_shade">
                        {breakin.claim_number || "N.A"}
                      </p>
                    </div>
                    <div className="vehicle_no">
                      <p className="grey_shade">Vehicle Reg. type</p>
                      <p className="dark_shade">
                        {breakin.registration || "N.A"}
                      </p>
                    </div>
                    <div className="inspection_date">
                      <p className="grey_shade">Accident Date</p>
                      <p className="dark_shade">{breakin.loss_date || "N.A"}</p>
                    </div>
                    <div className="make">
                      <p className="grey_shade">Make</p>
                      <p className="dark_shade">
                        {breakin.vehicle_make || "N.A"}
                      </p>
                    </div>
                    <div className="inspection_time">
                      <p className="grey_shade">Accident Time</p>
                      <p className="dark_shade">{breakin.loss_time || "N.A"}</p>
                    </div>
                    <div className="model">
                      <p className="grey_shade">Model</p>
                      <p className="dark_shade">
                        {breakin.vehicle_model || "N.A"}
                      </p>
                    </div>
                    <div className="name">
                      <p className="grey_shade">Insured Name</p>
                      <p className="dark_shade">
                        {breakin.insured_name || "N.A"}
                      </p>
                    </div>
                  </div>
                  {/* <div className="terms_checkbox">
                  <input
                    type="checkbox"
                    name="tnC"
                    checked={tnc}
                    value={tnc}
                    onChange={(e) => setTnC(e.target.checked)}
                  />
                  <label htmlFor="tnC" className="tnC">
                    I agree to HDFC ERGO{" "}
                    <span onClick={() => navigate("/terms&conditions")}>
                      Terms & Conditions
                    </span>
                  </label>
                </div> */}
                  <div className="inspection_btn">
                    <button
                      style={{ background: "#EC6466" }}
                      onClick={() =>
                        breakin.is_ai_photos_uploaded &&
                        !breakin.no_cdf_sources.includes(breakin.source_app)
                          ? navigate("/upload-cdf")
                          : navigate("/inspection")
                      }
                    >
                      START INSPECTION
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <Loader />
            </>
          )}
        </>
      );
    } else {
      return (
        <>
          {Object.keys(breakin).length !== 0 ? (
            <>
              <div className="container">
                <div className="header_container">
                  <span>Inspection details</span>
                  <p>
                    please complete the inspection to process your policy
                    request
                  </p>
                </div>
                <div className="box_container">
                  <div className="car_details">
                    <div className="inspection_id">
                      <p className="grey_shade">Inspection ID</p>
                      <p className="dark_shade">{breakin.BRKIN_ID || "N.A"}</p>
                    </div>
                    <div className="vehicle_no">
                      <p className="grey_shade">Vehicle reg. no.</p>
                      <p className="dark_shade">
                        {breakin.REGISTRATION_NUMBER || "N.A"}
                      </p>
                    </div>
                    <div className="inspection_date">
                      <p className="grey_shade">Inspection Date</p>
                      <p className="dark_shade">
                        {breakin.INSPECTIONDATE || "N.A"}
                      </p>
                    </div>
                    <div className="make">
                      <p className="grey_shade">Make</p>
                      <p className="dark_shade">{breakin.MAKE || "N.A"}</p>
                    </div>
                    <div className="inspection_time">
                      <p className="grey_shade">Inspection Time</p>
                      <p className="dark_shade">N.A.</p>
                    </div>
                    <div className="model">
                      <p className="grey_shade">Model</p>
                      <p className="dark_shade">{breakin.MODEL || "N.A"}</p>
                    </div>
                  </div>
                  <div className="terms_checkbox">
                    <input
                      type="checkbox"
                      name="tnC"
                      checked={tnc}
                      value={tnc}
                      onChange={(e) => setTnC(e.target.checked)}
                    />
                    <label htmlFor="tnC" className="tnC">
                      I agree to HDFC ERGO{" "}
                      <span onClick={() => navigate("/terms&conditions")}>
                        Terms & Conditions
                      </span>
                    </label>
                  </div>
                  <div className="inspection_btn">
                    <button
                      disabled={!tnc}
                      style={{ background: !tnc ? "#B0B0B0" : "#EC6466" }}
                      onClick={() => navigate("/inspection")}
                    >
                      START INSPECTION
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <Loader />
            </>
          )}
        </>
      );
    }
  }
};

export default Home;
