import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  carCount,
  setCars,
  setRetake,
  setUploaded,
} from "../Redux/Features/carSlice";
import Modal from "../Components/Modal";
import Tick from "../Assets/Images/Vector.png";
import Close from "../Assets/Images/Close.svg";
import CarF from "../Assets/Images/carF.svg";
import CarT from "../Assets/Images/carT.svg";
import Uploading from "../Assets/Images/uploading.svg";
import Logo from "../Assets/Images/logo_hdfc.png";
import axios from "axios";
import { Circle } from "rc-progress";
import uploadFinished from "../Assets/Images/uploadFinished.svg";
import uploadArrow from "../Assets/Images/uploadArrow.svg";
import DamageForm from "../Components/DamageForm";
import {
  setDamageRecorded,
  setDamageData,
} from "../Redux/Features/damageSlice";

const Upload = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [previewData, setPreviewData] = useState(null);
  const car = useSelector((state) => state.car);
  const isClaim = useSelector((state) => state.isClaim);
  const isPAYD = useSelector((state) => state.isPAYD);
  const modelWrapper = useSelector((state) => state.model);
  const breakin = useSelector((state) => state.breakin);
  const [whatsappSend, setWhatsappSend] = useState(false);

  const [isClaimDamageLive, setIsClaimDamageLive] = useState(true);
  const [submitBtnVisible, setSubmitBtnVisible] = useState(true);
  const [storageValue, setStorageValue] = useState(
    localStorage.getItem("lc_damage")
  );

  // console.log("uploaded images", car.uploaded)

  const [updateCar, setUpdatedCar] = useState([]);
  // console.log("uopdatecar", updateCar)

  const [submitBtn, setSubmitBtn] = useState(false);
  const [submitName, setSubmitname] = useState("Upload");

  useEffect(() => {
    // console.log("modelWrapper.validation", modelWrapper.validation)
    const tempCar = [];
    car.data.forEach((e) => {
      const validation = modelWrapper.validation.find((j) => j.key === e.key);
      if (validation && validation.result) {
        const detection = validation.result.car;
        const carInFrame = validation.result.car_in_frame;
        const d_precision = validation.result.d_precision;
        const h_precision = validation.result.h_precision;
        const v_precision = validation.result.v_precision;
        const final_side = validation.result.final_side;
        const isCarValue = detection
          ? e.imgId.replace(/_/g, "") !== final_side
            ? "Wrong Angle "
            : carInFrame
            ? "Car Detected"
            : d_precision <= -2
            ? "Too Far"
            : d_precision >= 2
            ? "Too Close"
            : h_precision <= -2
            ? "Too Left"
            : h_precision >= -2
            ? "Too Right"
            : v_precision <= -2
            ? "Too Up"
            : v_precision >= -2 && "Too Down"
          : "Car Not Detected";

        tempCar.push({
          ...e,
          progress: 0,
          isCar: validation
            ? validation.result.car &&
              validation.result.car_in_frame &&
              e.imgId.replace(/_/g, "") === final_side
            : true,
          message: validation ? isCarValue : "",
        });
      } else tempCar.push({ ...e, progress: 0 });
    });
    setUpdatedCar(tempCar);
  }, []);

  //   useEffect(() => {
  //     const checkLocalStorage = () => {
  //     const updatedValue = localStorage.getItem('lc_damage');
  //     console.log(updatedValue);
  //     // if(updatedValue !== storageValue){
  //     //     setStorageValue(updatedValue);
  //     // }
  //   };
  //   if(!!updatedValue){
  //     useDispatch(setDamageData(damageData));
  //     useDispatch(isDamageRecorded(true));
  //   }
  //     const interval = setInterval(checkLocalStorage, 1000);
  //     return () => clearInterval(interval);
  // },[storageValue]);

  // useEffect(() => {
  //   if(updateCar.length>0){
  //     console.log("progress",progress)
  //   let tempUpdate = JSON.parse(JSON.stringify(updateCar));
  //   tempUpdate.forEach((e)=>{
  //     progress.forEach((j)=>{
  //       if(e.key===j.key){
  //         e.progress=j.progress
  //       }
  //     })
  //   })
  //   setUpdatedCar(tempUpdate)
  // }

  // }, [progress])

  // useEffect(()=>{
  //   if(car.uploaded ){
  //     navigate('/thank')
  // }
  // },[])

  if (car.uploaded) navigate("/thank");

  const dataURItoBlob = async (dataURI, fileName) => {
    let byteString = atob(dataURI.split(",")[1]);

    // separate out the mime component
    let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to an ArrayBuffer
    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    //New Code
    let bg = new Blob([ab], { type: mimeString });
    return new File([bg], `${fileName}.jpg`, { type: "image/jpeg" });
  };
  const isEveryUpload = updateCar.every((item) => item.progress >= 100);

  const onSubmit = async () => {
    console.log({ submitName });
    let isError = false; // Flag to track errors
    if (submitName === "Upload") {
      setSubmitBtn(true);
      setSubmitname("Submit");
      if (isClaim) {
        if (!breakin?.claim_number) {
          navigate("/upload-error");
        }
        if (!breakin.no_cdf_sources.includes(breakin.source_app)) {
          setSubmitBtnVisible(false);
          document.title = "Claim Damage Form";
        }
        console.log(localStorage.getItem("lc_damage"));
        localStorage.removeItem("lc_damage");
        console.log(localStorage.getItem("lc_damage"));
      } else {
        if (!breakin?.BRKIN_ID) {
          navigate("/upload-error");
        }
      }
      // updateCar.forEach(async function(e, index, arr) {
      for await (let e of updateCar) {
        let result;
        let response;
        let imagefile = await dataURItoBlob(e.isUploadImg, e.imgId, e.name);
        // console.log(e);
        let dat1 = new FormData();

        if (isClaim) {
          dat1.append("claim_num", breakin.claim_number);
          dat1.append("user_id", breakin.insured_name);
          dat1.append("last_image", e.name === "ODO Meter" ? "1" : "0");
          dat1.append("image_name", e.imgId);
          dat1.append(
            "source_app",
            breakin.source_app ? breakin.source_app : "WAPC"
          );
          // dat1.append("upload_type", car.retake ? "reupload" : "upload");
          dat1.append("upload_type", "upload");
          dat1.append("vehicle_type_id", "1");
          dat1.append("keep_images", "[]");
          dat1.append("DocType", "Image"); //
          dat1.append("DocID", "100456");
          dat1.append("DocName", e.imgId);
          dat1.append("claimType", "Own Damage");
          dat1.append("claimDocType", "Photos - First Inspection - Customer");
          dat1.append("policyNo", breakin.pol_num);
          dat1.append("surveyDate", breakin.loss_date ? breakin.loss_date : "");
          dat1.append("surveyTime", breakin.loss_time ? breakin.loss_time : "");
          dat1.append("fileCount", "1");
          let claimnocd = breakin.claim_number;
          claimnocd = claimnocd.replace("C", "") + "00000001";
          dat1.append("claimNoCd", claimnocd);
          dat1.append("source_name", `${process.env.REACT_APP_SOURCE_APP}`);
          dat1.append("first_image", "1");
          dat1.append("image", imagefile);
          dat1.append(
            "Vehicle_Reg_No",
            breakin.registration ? breakin.registration : ""
          );
          dat1.append("lat", e.latitude ?? 19.1624);
          dat1.append("lon", e.longitude ?? 72.9376);
        }
        else if(isPAYD){
          dat1.append("claim_num", breakin.BRKIN_ID);
          dat1.append("user_id", breakin.Name);
          dat1.append("vehicle_type_id", "1");
          dat1.append("last_image", e.imgId === "ODO_Meter" || String(updateCar.length) === String(e.key) ? "1" : "0");
          dat1.append("image_name", e.imgId);
          // dat1.append("upload_type", car.retake ? "reupload" : "upload");
          dat1.append("upload_type", "upload");
          dat1.append("source_app", "PAYD_WC");
          dat1.append("image", imagefile);
          dat1.append("keep_images", "[]");
          dat1.append("DocType", "Image");
          dat1.append("DocID", "100456");
          dat1.append("DocName", e.imgId);
          dat1.append("UserType", "Customer");
          dat1.append("surveyor_name", "user");
          dat1.append("inbox_code", "23");
          dat1.append("claimType", "Own Damage");
          dat1.append("claimDocType", "Photos - First Inspection - Customer");
          dat1.append("policyNo", "2312204037231600000");
          dat1.append("surveyDate", breakin.INSPECTIONDATE);
          dat1.append("surveyTime", "");
          dat1.append("fileCount", "1");
          dat1.append("claimNoCd", "100456");
          dat1.append("source_name", `${process.env.REACT_APP_SOURCE_APP}`);
          dat1.append("first_image", "1");
          dat1.append("Vehicle_Reg_No", breakin.REGISTRATION_NUMBER);
          dat1.append("Product_Type", breakin.Product_x0020_Type);
          dat1.append("lat", e.latitude ?? 19.1624);
          dat1.append("lon", e.longitude ?? 72.9376);

        }
        else {

          dat1.append("claim_num", breakin.BRKIN_ID);
          dat1.append("user_id", breakin.Name);
          dat1.append("vehicle_type_id", "1");
          dat1.append(
            "last_image",
            e.imgId === "RC_Book_Back" ||
              String(updateCar.length) === String(e.key)
              ? "1"
              : "0"
          );
          dat1.append("image_name", e.imgId);
          // dat1.append("upload_type", car.retake ? "reupload" : "upload");
          dat1.append("upload_type", "upload");
          dat1.append("source_app", "WAPB");
          dat1.append("image", imagefile);
          dat1.append("keep_images", "[]");
          dat1.append("DocType", "Image");
          dat1.append("DocID", "100456");
          dat1.append("DocName", e.imgId);
          dat1.append("UserType", "Customer");
          dat1.append("surveyor_name", "user");
          dat1.append("inbox_code", "23");
          dat1.append("claimType", "Own Damage");
          dat1.append("claimDocType", "Photos - First Inspection - Customer");
          dat1.append("policyNo", "2312204037231600000");
          dat1.append("surveyDate", breakin.INSPECTIONDATE);
          dat1.append("surveyTime", "");
          dat1.append("fileCount", "1");
          dat1.append("claimNoCd", "100456");
          dat1.append("source_name", `${process.env.REACT_APP_SOURCE_APP}`);
          dat1.append("first_image", "1");
          dat1.append("Vehicle_Reg_No", breakin.REGISTRATION_NUMBER);
          dat1.append("Product_Type", breakin.Product_x0020_Type);
          dat1.append("lat", e.latitude ?? 19.1624);
          dat1.append("lon", e.longitude ?? 72.9376);
        }

        // if (navigator.geolocation) {
        //   navigator.geolocation.getCurrentPosition((position) => {
        //     const lat = position.coords.latitude;
        //     const lon = position.coords.longitude;
        //     dat1.append("lat", lat);
        //     dat1.append("lon", lon);
        //   });
        // }
        // else {
        //   dat1.append("lat", "1.148638");
        //   dat1.append("lon", "2.881086");
        // }

        var config = {
          method: "post",
          url: `${process.env.REACT_APP_API_URL}/ai_image_upload`,
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_STATIC_API_TOKEN}`,
          },
          onUploadProgress: (data) => {
            //   console.log("data", data.loaded);
            //   //Set the progress value to show the progress bar
            const percentCompleted = Math.round(
              (data.loaded / data.total) * 100
            );
            console.log(
              "percentCompleted of car ",
              `${percentCompleted} of ${e.name}`
            );
            //     let tempData = JSON.parse(JSON.stringify(arr));
            //  const foundIndex = tempProgress.findIndex(
            //           (j) => j.key === e.key
            //         );
            //         console.log("foundIndex",foundIndex)
            //         if(foundIndex>-1){
            //           tempProgress[foundIndex].progress=percentCompleted
            //         }
            updateCar[e.key - 1].progress = percentCompleted;
            // console.log("arr",arr)

            setUpdatedCar([...updateCar]);

            // const tempProgress= [...progress]
            // const foundIndex = tempProgress.findIndex(
            //   (j) => j.key === e.key
            // );
            // console.log("foundIndex",foundIndex)
            // if(foundIndex>-1){
            //   tempProgress[foundIndex].progress=percentCompleted
            // } else {
            //   tempProgress.push({
            //     name:e.name,
            //     progress:percentCompleted
            //   })
            // }
          },
          data: dat1,
        };

        await axios(config)
          .then(function (response) {
            result = JSON.stringify(response.data);
            return result;
          })
          // eslint-disable-next-line no-loop-func
          .catch(function (error) {
            // isError = true;
            console.log(error);
          });
        // try {
        //   response = await axios(config);

        //   // if (response.error || (response.data && response.data.error) || response.data.Upload_status == "0") {
        //   //   isError = true;
        //   //   break;
        //   // }
        //   result = JSON.stringify(response.data);
        //   return result;
        // } catch (error) {
        //   console.log(error);
        //   isError = true; // Set error flag to true
        //   break; // Exit the loop if error occurs
        // }
      }
    } else if (submitName === "Submit") {
      dispatch(setUploaded());
      navigate("/thank");
    }
    // if (isError) {
    //   navigate("/upload-error")
    // }
  };

  useEffect(() => {
    if (!submitBtnVisible && isClaim && isClaimDamageLive) {
      console.log(document.body.scrollHeight);
      window.scrollTo({
        top: 1450,
        behavior: "smooth",
      });
    }
  }, [submitBtnVisible]);

  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };
    window.addEventListener("beforeunload", unloadCallback);
    return () => {
      window.removeEventListener("beforeunload", unloadCallback);
    };
  }, []);

  useEffect(() => {
    console.log("car.uploaded", car.uploaded);
    if (updateCar.length > 0 && !whatsappSend && !isClaim) {
      console.log("updateCar", updateCar);
      console.log("isEveryUpload", isEveryUpload);
      // if(car.uploaded || isEveryUpload){
      if (car.uploaded || isEveryUpload) {
        setWhatsappSend(true);
        const requestOptions = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_STATIC_API_TOKEN}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            mobile_no: breakin.MOBILE_NUMBER,
            template: "MYRA_VEHICLE_INSPECTION_SUCCESS",
          }),
        };

        fetch(
          `${process.env.REACT_APP_API_URL}/wp_send_sms`,
          requestOptions
        ).catch((error) => {
          console.log("error");
        });

        //   setTimeout(() => {
        //     navigate('/thank')
        // }, 3000);
      } else {
      }
    }
  }, [updateCar]);

  // if(isEveryUpload) dispatch(setUploaded())
  return (
    <>
      <div className="container">
        <div className="header_container">
          <span>Please click {submitName}</span>
          <p>to {submitName.toLowerCase()} your files</p>
        </div>
        {updateCar && updateCar.length > 0
          ? updateCar.map((e) => (
              <div className="upload_container" key={e.key}>
                <div className="image">
                  <div className="predicted" style={{ position: "relative" }}>
                    {e.isUpload && (
                      <img
                        id="prediction"
                        className="prediction"
                        src={e.isCar ? CarT : CarF}
                        alt={e.isCar ? "/" : "X"}
                      />
                    )}
                    <img
                      className="upload_img"
                      onClick={() => {
                        setModalOpen(true);
                        setPreviewData(e);
                      }}
                      src={e.isUploadImg ? e.isUploadImg : e.stencil}
                      alt="car"
                    />
                  </div>
                  <div className="name">
                    <p className="e.imgId_name">{e.name.toUpperCase()}</p>

                    {!submitBtn && (
                      <button
                        // style={{backgroundColor:e.toValidate?e.isCar?"green":"red":"#888888"}}
                        style={{ backgroundColor: "#888888" }}
                        onClick={() => {
                          // const copyData = JSON.parse(JSON.stringify(car.data));
                          // const foundIndex = copyData.findIndex(
                          //   (elem) => Number(elem.key) === Number(e.key)
                          // );
                          // if (foundIndex > -1) {
                          //   copyData[foundIndex].isUploadImg = false;
                          //   copyData[foundIndex].isUpload = false;
                          // }

                          // dispatch(setCars(copyData));

                          dispatch(carCount(e.key));
                          dispatch(setRetake());
                          navigate("/inspection");
                        }}
                      >
                        Retake
                      </button>
                    )}
                  </div>
                </div>
                {submitBtn && (
                  <div className="detected">
                    {e.progress < 100 && (!car.uploaded || isEveryUpload) ? (
                      <div
                        style={{
                          width: "44px",
                          height: "44px",
                          position: "relative",
                        }}
                      >
                        <Circle
                          percent={e.progress}
                          strokeWidth={5}
                          steps={{
                            count: 10,
                            space: 7,
                          }}
                          strokeColor={"#e42125"}
                        />
                        <img
                          src={uploadArrow}
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "11px",
                          }}
                          alt={`${e.progress}`}
                        />
                      </div>
                    ) : (
                      <img src={uploadFinished} alt="Uploaded" />
                    )}
                    <p
                      className="upload_text"
                      style={{ color: e.progress < 100 ? "#EF7A7C" : "green" }}
                    >
                      {e.progress < 100 ? "Uploading..." : "Upload Finished"}
                    </p>
                  </div>
                )}
              </div>
            ))
          : null}

        {!breakin.no_cdf_sources.includes(breakin.source_app) &&
        isClaim &&
        isClaimDamageLive & (submitName === "Submit") ? (
          <DamageForm />
        ) : (
          <button
            className="submitBtn"
            disabled={submitName === "Submit" && !isEveryUpload}
            style={{
              background:
                submitName === "Upload"
                  ? "#EC6466"
                  : !isEveryUpload
                  ? "#B0B0B0"
                  : "#0F871B",
              display: submitBtnVisible ? "block" : "none",
            }}
            onClick={onSubmit}
          >
            <span>{submitName}</span>
          </button>
        )}
      </div>
      {modalOpen && (
        <Modal>
          <div className="modalContainerRotate ">
            <div className="titleCloseBtn">
              <button
                onClick={() => {
                  setModalOpen(false);
                }}
              >
                <img src={Close} alt="X" />
              </button>
            </div>
            <div className="body">
              <h2>{previewData.name}</h2>
              <img src={previewData.isUploadImg} alt="car" />
              {!submitBtn && (
                <button
                  onClick={() => {
                    // const copyData = JSON.parse(JSON.stringify(car.data));
                    // const foundIndex = copyData.findIndex(
                    //   (elem) => Number(elem.key) === Number(previewData.key)
                    // );
                    // if (foundIndex > -1) {
                    //   copyData[foundIndex].isUploadImg = false;
                    //   copyData[foundIndex].isUpload = false;
                    // }

                    // dispatch(setCars(copyData));

                    dispatch(carCount(previewData.key));
                    dispatch(setRetake());
                    navigate("/inspection");
                  }}
                  id="cancelBtn"
                >
                  Retake
                </button>
              )}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Upload;
